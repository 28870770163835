import React, { Component } from 'react';
import withContext from 'store/withContext';

var apiEnv = process.env.NODE_ENV === 'development' ? 'http://localhost:3006' : 'https://api.hirezstudios.com';

class CampaignView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: undefined,
      error: false,
      keyStatus: undefined
    };
  }

  renderURL = (game) => {
    switch(game) {
      case 'Rogue Company':
        return 'https://www.roguecompany.com';
      case 'SMITE':
        return 'https://www.smitegame.com';
      case 'Paladins':
        return 'https://www.paladins.com';
      case 'DKO':
        return 'https://www.divineknockout.com';
      default:
        return 'https://www.smitegame.com';
    }
  }

  updateFields = (e, title) => {
    this.setState({[title]: e.target.value});
  }

  getAvailableKeys = () => {
    fetch(`${apiEnv}/keydist/availablekeys`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic doug$$$$$mith'
      },
      body: `{
        "campaignId": "${this.props.campaign._id}"
      }`
    })
    .then(response => response.json())
    .then(result => { 
      this.setState({keyStatus: result});
    })
  }

  editCampaign = () => {
    let editData = Object.assign({}, this.state);
    delete editData.error;
    delete editData.response;
    delete editData.keyStatus;
    editData.campaignId = this.props.campaign._id

    fetch(`${apiEnv}/keydist/edit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${this.props.jwt ? this.props.jwt : ""}`
      },
      body: JSON.stringify(editData)
    })
    .then(response => response.json())
    .then(result => { 
      if (result.response) {
        this.setState({response: result.response, error: false});
        this.props.updateCampaignFunc();
      }
      else this.setState({response: `Something went wrong. Ask Alissa about it.`, error: true});
    })
  }

  componentDidMount() {
    if (this.props.campaign === undefined) {
      this.props.context.actions.updateRoute(null);
    }
    else {
      this.getAvailableKeys();
    }
  }
  
  render() {
    return (
      <div className="keys-edit">
        {this.state.keyStatus &&
        <p className="key-status">There are {this.state.keyStatus.available} of {this.props.campaign.keyTotal} keys available.</p>
        }
        <label>
          Title:
          <input id="title"  defaultValue={this.props.campaign ? this.props.campaign.name : ''} onBlur={(e) => this.updateFields(e, 'name')} />
        </label>
        <div className="dropdown website">
          <label>Game: </label>
          <select className="list" defaultValue={this.props.campaign ? this.props.campaign.game : ''} onChange={(e)=>this.updateFields(e, 'game')}>
            <option value="">Please Select</option>
            <option value="DKO">DKO</option>
            <option value="Rogue Company">Rogue Company</option>
            <option value="Smite">SMITE</option>
            <option value="Paladins">Paladins</option>
          </select>
        </div>
        <div className="dropdown platform">
          <label>Platform: </label>
          <select className="list" defaultValue={this.props.campaign ? this.props.campaign.platform : ''} onChange={(e)=>this.updateFields(e, 'platform')}>
            <option value="">Please Select</option>                        
            <option value="Xbox">Xbox One</option>
            <option value="PS4">PS4</option>
            <option value="Switch">Nintendo Switch</option>
            <option value="EGS">Epic Games Store</option>
            <option value="Steam">Steam</option>
          </select>
        </div>
        <div className="dropdown region">
          <label>Region: </label>
          <select className="list" defaultValue={this.props.campaign ? this.props.campaign.region : ''} onChange={(e)=>this.updateFields(e, 'region')}>
            <option value="">Please Select</option>                        
            <option value="NA">NA</option>
            <option value="EU">EU</option>
          </select>
        </div>
        <div className="dropdown status">
          <label>Status: </label>
          <select className="list" defaultValue={this.props.campaign ? this.props.campaign.status : 'live'} onChange={(e)=>this.updateFields(e, 'status')}>
            <option value="live">Live</option>
            <option value="paused">Paused</option>
            <option value="ended">Ended</option>
          </select>
        </div>
        {this.props.campaign &&
        <p className="link">Campaign link: {`${this.renderURL(this.props.campaign.game)}/keys?id=${this.props.campaign._id}`}</p>
        }
        <div className="buttons">
          <button className="button" onClick={this.editCampaign}>Update Campaign Data</button>
        </div>
        <h3 className={`response ${this.state.error ? 'error' : ''}`}>{this.state.response}</h3>
      </div>
    );
  };
}

export default withContext(CampaignView)