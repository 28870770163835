import React, { Component } from 'react';

var apiEnv = process.env.NODE_ENV === 'development' ? 'http://localhost:3006' : 'https://api.hirezstudios.com';

class CampaignCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: undefined,
      game: 'DKO',
      platform: undefined,
      author: this.props.author,
      region: undefined,
      status: 'live',
      keys: undefined,
      response: undefined,
      error: false
    };
  }

  saveKeys = (event) => {
    var reader = new FileReader();
    reader.readAsText(event.target.files[0]);
    reader.onloadend = function (evt) {
      if (evt.target.readyState === FileReader.DONE) {
        const csvString = evt.target.result.split("\r\n");
        this.setState({
          keys: csvString
        })
      }
    }.bind(this)
  }

  updateFields = (e, title) => {
    this.setState({[title]: e.target.value});
  }

  createCampaign = () => {
    if (!this.state.keys || !this.state.platform || !this.state.name) {
      this.setState({response: 'No blank fields, please', error: true});
    }
    else {
      fetch(`${apiEnv}/keydist/postcampaign`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic doug$$$$$mith'
        },
        body: `{
          "keys": ${JSON.stringify(this.state.keys)},
          "platform": "${this.state.platform}",
          "game": "${this.state.game}",
          "name": "${this.state.name}",
          "author": "${this.state.author}",
          "status": "${this.state.status}",
          "keyTotal": "${this.state.keys.length.toString()}",
          "jwt": "${this.props.jwt ? this.props.jwt : ""}"
        }`
      })
      .then(response => response.json())
      .then(result => { 
        if (result.id) {
          this.setState({response: `Click here to copy the campaign url: https://www.roguecompany.com/keys?id=${result.id}`, error: false, id: result.id});
          this.props.updateCampaignFunc();
        }
        else this.setState({response: `Something went wrong. Ask Alissa about it.`, error: true});
      })
    }
  }

  copyLink = () => {
    const link = document.querySelector('#create-link');
    console.log(link);
    link.select();
    document.execCommand('copy');
  }
  
  render() {
    return (
      <div className="keys-create">
        <label>
          Title:
          <input id="title" placeholder="Enter a campaign title" onBlur={(e) => this.updateFields(e, 'name')} />
        </label>
        <div className="dropdown website">
          <label>Game: </label>
          <select className="list" defaultValue={this.props.campaign ? this.props.campaign.game : ''} onChange={(e)=>this.updateFields(e, 'game')}>
            <option value="">Please Select</option>
            <option value="DKO">DKO</option>                        
            <option value="Rogue Company">Rogue Company</option>
            <option value="Smite">SMITE</option>
            <option value="Paladins">Paladins</option>
          </select>
        </div>
        <div className="dropdown platform">
          <label>Platform: </label>
          <select className="list" defaultValue={this.props.campaign ? this.props.campaign.platform : ''} onChange={(e)=>this.updateFields(e, 'platform')}>
            <option value="">Please Select</option>                        
            <option value="Xbox">Xbox One</option>
            <option value="PS4">PS4</option>
            <option value="Switch">Nintendo Switch</option>
            <option value="EGS">Epic Games Store</option>
            <option value="Steam">Steam</option>
          </select>
        </div>
        <div className="dropdown region">
          <label>Region: </label>
          <select className="list" defaultValue={this.props.campaign ? this.props.campaign.status : ''} onChange={(e)=>this.updateFields(e, 'region')}>
            <option value="">Please Choose</option>
            <option value="NA">NA</option>
            <option value="EU">EU</option>
          </select>
        </div>
        <div className="dropdown status">
          <label>Status: </label>
          <select className="list" defaultValue={this.props.campaign ? this.props.campaign.status : ''} onChange={(e)=>this.updateFields(e, 'status')}>
            <option value="live">Live</option>
            <option value="paused">Paused</option>
            <option value="ended">Ended</option>
          </select>
        </div>
        <div className="upload">
          <input className="file-upload" type="file" accept=".csv" id="fileContents" onChange={e => this.saveKeys(e)} />
          <div className="buttons">
            <button className={`button ${this.state.show ? 'show' : ''} btn btn-upload`} onClick={this.createCampaign}>Create</button>
          </div>
        </div>
        <h3 className={`response ${this.state.error ? 'error' : ''}`} onClick={this.copyLink}>{this.state.response}</h3>
        <input id="create-link" className="hidden-link" readOnly value={`https://www.roguecompany.com/keys?id=${this.state.id}`} />
      </div>
    );
  };
}

export default CampaignCreate