import React from 'react';
import AppWrapper from 'styles/keys.css';
import CampaignCreate from 'components/Keys/CampaignCreate';
import CampaignView from 'components/Keys/CampaignView';
import withContext from 'store/withContext';

var apiEnv = process.env.NODE_ENV === 'development' ? 'http://localhost:3006' : 'https://api.hirezstudios.com';

class Keys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeGame: 'Rogue Company',
      editingCampaign: undefined,
      jwt: undefined
    }
  }

  renderURL = (game) => {
    switch(game) {
      case 'Rogue Company':
        return 'https://www.roguecompany.com';
      case 'SMITE':
        return 'https://www.smitegame.com';
      case 'Paladins':
        return 'https://www.paladins.com';
      case 'DKO':
        return 'https://www.divineknockout.com';
      default:
        return 'https://www.smitegame.com';
    }
  }

  editCampaign = (campaign) => {
    this.setState({editingCampaign: campaign});
    this.updateRoute('keys-edit');
  }

  duplicateCampaign = (campaign) => {
    this.setState({editingCampaign: campaign});
    this.updateRoute('keys-new');
  }

  updateRoute = (route) => {
    this.props.context.actions.updateRoute(route);
  }

  getCampaigns = () => {
    fetch(`${apiEnv}/keydist/lookup/all`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${this.state.jwt ? this.state.jwt : ""}`,
      }
    }).then((response) => response.json()).then((response) => {
      this.setState({campaigns: response});
    });
  }

  selectGame = (game) => {
    this.setState({activeGame: game});
    this.getCampaigns(game);
  }

  sortByDate = (a,b) => {
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    else return 0;
  }
  
  copyLink = (e, id) => {
    console.log(e);
		e.stopPropagation();
    var link = document.querySelector(`#link-${id}`);
    link.select();
    document.execCommand('copy');
  }

  readCookie = (name) => {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  componentDidMount() {
    this.updateRoute(null);
    this.getCampaigns(this.state.activeGame);
    let jwtCookie = this.readCookie("jwt");
    if (jwtCookie) {
      this.setState({jwt: jwtCookie})
    }
  }

  render() {
    return (
      <AppWrapper className="keys">
        {(this.props.context.availableTools && (this.props.context.availableTools.includes('keys'))) && 
        <div>
          <div className="home button" onClick={() => this.updateRoute(null)}>Back to Keys Home</div>
          {/* <DropDownLists stateLiftFunc={this.selectGame} /> */}
          {this.props.context.route === null &&
          <div className="route home">
            <div className="button create" onClick={() => this.updateRoute('keys-new')}>Create a campaign</div>
            <div />
            <div className="campaigns">
              {this.state.campaigns && this.state.campaigns.sort(this.sortByDate).map(campaign => 
                <div key={campaign._id} className="campaign">
                  <p>{campaign.name}</p>
                  <div className="buttons">
                    <button onClick={() => this.editCampaign(campaign)}>View</button>
                    <button onClick={() => this.duplicateCampaign(campaign)}>Duplicate</button>
                  </div>
                  <p className="link" onClick={(e) => this.copyLink(e, campaign._id)}>Campaign link: {`${this.renderURL(campaign.game)}/keys?id=${campaign._id}`}</p>
                  <input readOnly className="hidden-link" id={`link-${campaign._id}`} defaultValue={`${this.renderURL(campaign.game)}/keys?id=${campaign._id}`} />
                  <p className="status">Status: {campaign.status}</p>
                </div>
              )}
            </div>
          </div>
          }
          {this.props.context.route === 'keys-new' &&
          <CampaignCreate 
            author={this.props.context.userName} updateCampaignFunc={this.getCampaigns}
            campaign={this.state.editingCampaign}
            jwt={this.state.jwt ? this.state.jwt : ""}
          />
          }
          {this.props.context.route === 'keys-edit' &&
          <CampaignView 
            author={this.props.context.userName}
            updateCampaignFunc={this.getCampaigns}
            campaign={this.state.editingCampaign} 
            jwt={this.state.jwt ? this.state.jwt : ""}
          />
          }
        </div>
        }
      </AppWrapper>
    );
  }
}

export default withContext(Keys);
